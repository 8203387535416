import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _095a038b = () => interopDefault(import('../pages/active/index.vue' /* webpackChunkName: "pages/active/index" */))
const _118e3a82 = () => interopDefault(import('../pages/bang-xep-hang/index.vue' /* webpackChunkName: "pages/bang-xep-hang/index" */))
const _1233d3a7 = () => interopDefault(import('../pages/blog/index.vue' /* webpackChunkName: "pages/blog/index" */))
const _2cb2d1b2 = () => interopDefault(import('../pages/bo-de/index.vue' /* webpackChunkName: "pages/bo-de/index" */))
const _24465a47 = () => interopDefault(import('../pages/callback.vue' /* webpackChunkName: "pages/callback" */))
const _5d77325d = () => interopDefault(import('../pages/dang-ky-gia-su/index.vue' /* webpackChunkName: "pages/dang-ky-gia-su/index" */))
const _e3d61c5e = () => interopDefault(import('../pages/dang-nhap/index.vue' /* webpackChunkName: "pages/dang-nhap/index" */))
const _684d9292 = () => interopDefault(import('../pages/doi-mat-khau/index.vue' /* webpackChunkName: "pages/doi-mat-khau/index" */))
const _5749400d = () => interopDefault(import('../pages/download/index.vue' /* webpackChunkName: "pages/download/index" */))
const _09319ad2 = () => interopDefault(import('../pages/goi-cuoc/index.vue' /* webpackChunkName: "pages/goi-cuoc/index" */))
const _d18f8ea0 = () => interopDefault(import('../pages/hoi-bai/index.vue' /* webpackChunkName: "pages/hoi-bai/index" */))
const _3d7e7fd3 = () => interopDefault(import('../pages/hoi-bai-cua-toi/index.vue' /* webpackChunkName: "pages/hoi-bai-cua-toi/index" */))
const _67bf83f8 = () => interopDefault(import('../pages/huong-dan-cong-diem/index.vue' /* webpackChunkName: "pages/huong-dan-cong-diem/index" */))
const _f9ae5a10 = () => interopDefault(import('../pages/huong-dan-su-dung/index.vue' /* webpackChunkName: "pages/huong-dan-su-dung/index" */))
const _bcd2d53a = () => interopDefault(import('../pages/khoa-hoc/index.vue' /* webpackChunkName: "pages/khoa-hoc/index" */))
const _5960d99e = () => interopDefault(import('../pages/khong-ton-tai/index.vue' /* webpackChunkName: "pages/khong-ton-tai/index" */))
const _5617921a = () => interopDefault(import('../pages/lich-su-thanh-toan/index.vue' /* webpackChunkName: "pages/lich-su-thanh-toan/index" */))
const _620d1bfe = () => interopDefault(import('../pages/maintenance.vue' /* webpackChunkName: "pages/maintenance" */))
const _2294ff1a = () => interopDefault(import('../pages/noi-quy-hoi-bai/index.vue' /* webpackChunkName: "pages/noi-quy-hoi-bai/index" */))
const _6d71533e = () => interopDefault(import('../pages/redirect/index.vue' /* webpackChunkName: "pages/redirect/index" */))
const _7b516e83 = () => interopDefault(import('../pages/tai-lieu/index.vue' /* webpackChunkName: "pages/tai-lieu/index" */))
const _48757bcc = () => interopDefault(import('../pages/thong-tin-ca-nhan/index.vue' /* webpackChunkName: "pages/thong-tin-ca-nhan/index" */))
const _b850c79e = () => interopDefault(import('../pages/thong-tin-khac/index.vue' /* webpackChunkName: "pages/thong-tin-khac/index" */))
const _66ef9e10 = () => interopDefault(import('../pages/thu-vien-cua-toi/index.vue' /* webpackChunkName: "pages/thu-vien-cua-toi/index" */))
const _04371340 = () => interopDefault(import('../pages/tim-kiem/index.vue' /* webpackChunkName: "pages/tim-kiem/index" */))
const _679ece9a = () => interopDefault(import('../pages/xac-thuc/index.vue' /* webpackChunkName: "pages/xac-thuc/index" */))
const _3ff50aba = () => interopDefault(import('../pages/blog/search.vue' /* webpackChunkName: "pages/blog/search" */))
const _3847b35f = () => interopDefault(import('../pages/bo-de/huong-dan.vue' /* webpackChunkName: "pages/bo-de/huong-dan" */))
const _f424e9ba = () => interopDefault(import('../pages/event/invite/index.vue' /* webpackChunkName: "pages/event/invite/index" */))
const _73593548 = () => interopDefault(import('../pages/thu-vien-cua-toi/bo-de/da-lam.vue' /* webpackChunkName: "pages/thu-vien-cua-toi/bo-de/da-lam" */))
const _5d987f97 = () => interopDefault(import('../pages/thu-vien-cua-toi/bo-de/tam-dung.vue' /* webpackChunkName: "pages/thu-vien-cua-toi/bo-de/tam-dung" */))
const _0f55a0b4 = () => interopDefault(import('../pages/thu-vien-cua-toi/khoa-hoc/dang-hoc.vue' /* webpackChunkName: "pages/thu-vien-cua-toi/khoa-hoc/dang-hoc" */))
const _560a1044 = () => interopDefault(import('../pages/thu-vien-cua-toi/khoa-hoc/theo-doi.vue' /* webpackChunkName: "pages/thu-vien-cua-toi/khoa-hoc/theo-doi" */))
const _47923cbf = () => interopDefault(import('../pages/thu-vien-cua-toi/tai-lieu/da-xem.vue' /* webpackChunkName: "pages/thu-vien-cua-toi/tai-lieu/da-xem" */))
const _5396f24a = () => interopDefault(import('../pages/blog/danh-muc/_slug.vue' /* webpackChunkName: "pages/blog/danh-muc/_slug" */))
const _2dfc4c17 = () => interopDefault(import('../pages/bo-de/chuyen-de/_slug.vue' /* webpackChunkName: "pages/bo-de/chuyen-de/_slug" */))
const _223b4565 = () => interopDefault(import('../pages/bo-de/ket-qua/_slug.vue' /* webpackChunkName: "pages/bo-de/ket-qua/_slug" */))
const _e995e5a0 = () => interopDefault(import('../pages/bo-de/lam-bai/_slug.vue' /* webpackChunkName: "pages/bo-de/lam-bai/_slug" */))
const _43029fb2 = () => interopDefault(import('../pages/khoa-hoc/chi-tiet/_slug.vue' /* webpackChunkName: "pages/khoa-hoc/chi-tiet/_slug" */))
const _4967e688 = () => interopDefault(import('../pages/khoa-hoc/chuyen-de/_slug.vue' /* webpackChunkName: "pages/khoa-hoc/chuyen-de/_slug" */))
const _3abdd7a8 = () => interopDefault(import('../pages/tai-lieu/chuyen-de/_slug.vue' /* webpackChunkName: "pages/tai-lieu/chuyen-de/_slug" */))
const _6a716c8c = () => interopDefault(import('../pages/thu-vien-cua-toi/bo-de/_slug.vue' /* webpackChunkName: "pages/thu-vien-cua-toi/bo-de/_slug" */))
const _1892ce6a = () => interopDefault(import('../pages/thu-vien-cua-toi/khoa-hoc/_slug.vue' /* webpackChunkName: "pages/thu-vien-cua-toi/khoa-hoc/_slug" */))
const _651d1c2a = () => interopDefault(import('../pages/thu-vien-cua-toi/tai-lieu/_slug.vue' /* webpackChunkName: "pages/thu-vien-cua-toi/tai-lieu/_slug" */))
const _107d325f = () => interopDefault(import('../pages/blog/_slug.vue' /* webpackChunkName: "pages/blog/_slug" */))
const _2afc306a = () => interopDefault(import('../pages/bo-de/_slug.vue' /* webpackChunkName: "pages/bo-de/_slug" */))
const _356f6716 = () => interopDefault(import('../pages/cau-hoi/_slug.vue' /* webpackChunkName: "pages/cau-hoi/_slug" */))
const _c04017ca = () => interopDefault(import('../pages/khoa-hoc/_slug.vue' /* webpackChunkName: "pages/khoa-hoc/_slug" */))
const _799acd3b = () => interopDefault(import('../pages/tai-lieu/_slug.vue' /* webpackChunkName: "pages/tai-lieu/_slug" */))
const _1fb0d976 = () => interopDefault(import('../pages/tra-loi/_slug.vue' /* webpackChunkName: "pages/tra-loi/_slug" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/active",
    component: _095a038b,
    name: "active"
  }, {
    path: "/bang-xep-hang",
    component: _118e3a82,
    name: "bang-xep-hang"
  }, {
    path: "/blog",
    component: _1233d3a7,
    name: "blog"
  }, {
    path: "/bo-de",
    component: _2cb2d1b2,
    name: "bo-de"
  }, {
    path: "/callback",
    component: _24465a47,
    name: "callback"
  }, {
    path: "/dang-ky-gia-su",
    component: _5d77325d,
    name: "dang-ky-gia-su"
  }, {
    path: "/dang-nhap",
    component: _e3d61c5e,
    name: "dang-nhap"
  }, {
    path: "/doi-mat-khau",
    component: _684d9292,
    name: "doi-mat-khau"
  }, {
    path: "/download",
    component: _5749400d,
    name: "download"
  }, {
    path: "/goi-cuoc",
    component: _09319ad2,
    name: "goi-cuoc"
  }, {
    path: "/hoi-bai",
    component: _d18f8ea0,
    name: "hoi-bai"
  }, {
    path: "/hoi-bai-cua-toi",
    component: _3d7e7fd3,
    name: "hoi-bai-cua-toi"
  }, {
    path: "/huong-dan-cong-diem",
    component: _67bf83f8,
    name: "huong-dan-cong-diem"
  }, {
    path: "/huong-dan-su-dung",
    component: _f9ae5a10,
    name: "huong-dan-su-dung"
  }, {
    path: "/khoa-hoc",
    component: _bcd2d53a,
    name: "khoa-hoc"
  }, {
    path: "/khong-ton-tai",
    component: _5960d99e,
    name: "khong-ton-tai"
  }, {
    path: "/lich-su-thanh-toan",
    component: _5617921a,
    name: "lich-su-thanh-toan"
  }, {
    path: "/maintenance",
    component: _620d1bfe,
    name: "maintenance"
  }, {
    path: "/noi-quy-hoi-bai",
    component: _2294ff1a,
    name: "noi-quy-hoi-bai"
  }, {
    path: "/redirect",
    component: _6d71533e,
    name: "redirect"
  }, {
    path: "/tai-lieu",
    component: _7b516e83,
    name: "tai-lieu"
  }, {
    path: "/thong-tin-ca-nhan",
    component: _48757bcc,
    name: "thong-tin-ca-nhan"
  }, {
    path: "/thong-tin-khac",
    component: _b850c79e,
    name: "thong-tin-khac"
  }, {
    path: "/thu-vien-cua-toi",
    component: _66ef9e10,
    name: "thu-vien-cua-toi"
  }, {
    path: "/tim-kiem",
    component: _04371340,
    name: "tim-kiem"
  }, {
    path: "/xac-thuc",
    component: _679ece9a,
    name: "xac-thuc"
  }, {
    path: "/blog/search",
    component: _3ff50aba,
    name: "blog-search"
  }, {
    path: "/bo-de/huong-dan",
    component: _3847b35f,
    name: "bo-de-huong-dan"
  }, {
    path: "/event/invite",
    component: _f424e9ba,
    name: "event-invite"
  }, {
    path: "/thu-vien-cua-toi/bo-de/da-lam",
    component: _73593548,
    name: "thu-vien-cua-toi-bo-de-da-lam"
  }, {
    path: "/thu-vien-cua-toi/bo-de/tam-dung",
    component: _5d987f97,
    name: "thu-vien-cua-toi-bo-de-tam-dung"
  }, {
    path: "/thu-vien-cua-toi/khoa-hoc/dang-hoc",
    component: _0f55a0b4,
    name: "thu-vien-cua-toi-khoa-hoc-dang-hoc"
  }, {
    path: "/thu-vien-cua-toi/khoa-hoc/theo-doi",
    component: _560a1044,
    name: "thu-vien-cua-toi-khoa-hoc-theo-doi"
  }, {
    path: "/thu-vien-cua-toi/tai-lieu/da-xem",
    component: _47923cbf,
    name: "thu-vien-cua-toi-tai-lieu-da-xem"
  }, {
    path: "/blog/danh-muc/:slug?",
    component: _5396f24a,
    name: "blog-danh-muc-slug"
  }, {
    path: "/bo-de/chuyen-de/:slug",
    component: _2dfc4c17,
    name: "bo-de-chuyen-de-slug"
  }, {
    path: "/bo-de/ket-qua/:slug",
    component: _223b4565,
    name: "bo-de-ket-qua-slug"
  }, {
    path: "/bo-de/lam-bai/:slug",
    component: _e995e5a0,
    name: "bo-de-lam-bai-slug"
  }, {
    path: "/khoa-hoc/chi-tiet/:slug",
    component: _43029fb2,
    name: "khoa-hoc-chi-tiet-slug"
  }, {
    path: "/khoa-hoc/chuyen-de/:slug",
    component: _4967e688,
    name: "khoa-hoc-chuyen-de-slug"
  }, {
    path: "/tai-lieu/chuyen-de/:slug",
    component: _3abdd7a8,
    name: "tai-lieu-chuyen-de-slug"
  }, {
    path: "/thu-vien-cua-toi/bo-de/:slug?",
    component: _6a716c8c,
    name: "thu-vien-cua-toi-bo-de-slug"
  }, {
    path: "/thu-vien-cua-toi/khoa-hoc/:slug?",
    component: _1892ce6a,
    name: "thu-vien-cua-toi-khoa-hoc-slug"
  }, {
    path: "/thu-vien-cua-toi/tai-lieu/:slug?",
    component: _651d1c2a,
    name: "thu-vien-cua-toi-tai-lieu-slug"
  }, {
    path: "/blog/:slug",
    component: _107d325f,
    name: "blog-slug"
  }, {
    path: "/bo-de/:slug?",
    component: _2afc306a,
    name: "bo-de-slug"
  }, {
    path: "/cau-hoi/:slug?",
    component: _356f6716,
    name: "cau-hoi-slug"
  }, {
    path: "/khoa-hoc/:slug?",
    component: _c04017ca,
    name: "khoa-hoc-slug"
  }, {
    path: "/tai-lieu/:slug?",
    component: _799acd3b,
    name: "tai-lieu-slug"
  }, {
    path: "/tra-loi/:slug?",
    component: _1fb0d976,
    name: "tra-loi-slug"
  }, {
    path: "/",
    component: _bcd2d53a,
    name: "index"
  }, {
    path: "/blog-app",
    component: _1233d3a7,
    name: "blog-app"
  }, {
    path: "/blog-app/search",
    component: _3ff50aba,
    name: "blog-app-search"
  }, {
    path: "/blog-app/:slug",
    component: _107d325f,
    name: "blog-app-slug"
  }, {
    path: "/blog-app/category/:slug",
    component: _5396f24a,
    name: "blog-app-category-slug"
  }, {
    path: "*",
    component: _5960d99e
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
